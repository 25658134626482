




import Vue from 'vue'
import ClientForm from '@/views/Clients/views/ClientForm.vue'

export default Vue.extend({
  name: 'ShowClient',
  components: { ClientForm },
  data: function (): { id: string | number } {
    const id = 'id' in this.$route.params && this.$route.params.id
    if (!id) {
      throw new Error('EditTender is missing required route param id')
    }
    return { id }
  },
})
